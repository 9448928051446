/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('../public/fonts/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('../public/fonts/CentraNo2-Book.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('../public/fonts/CentraNo2-Medium.ttf');
	font-weight: 400;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Centra', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;


  /* background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12); */
  /* background-color: #efefef; */
  background-image: linear-gradient(45deg,#125488, #d1ebff);
  
  min-height: 100%;
  height: 100vh;
  width: 100vw;
  position: fixed;

  overflow-x: hidden;
  overflow-y: auto;

  scroll-behavior: smooth;
  

}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */
